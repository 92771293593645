import React, { PureComponent } from 'react';
import {
  SEO,
  LevelHero,
  Inspiration,
  LevelChallenge,
  LevelBenefits,
  LevelObjective
} from 'components';
import styled, { css } from 'styled-components';
import checkmark from 'images/Levels/checkmark.svg';
import crossmark from 'images/Shared/crossmark.svg';
import crossmarkBlack from 'images/Levels/crossmark_black.svg';
import axios from 'axios';
import Rodal from 'rodal';
import Dropzone from 'react-dropzone';
import { isEdge, browserVersion } from 'react-device-detect';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';
import zipFilesWorker from 'utils/zipFilesWorker.js';
import level2Icon from 'images/Shared/LevelIcons/WhiteStroke/level_02_icon.svg';

class Level2 extends PureComponent {
  state = {
    componentHasMounted: false,
    changeDropZoneBackgroundColor: false,
    dropZoneModalIsOpen: false,
    email: '',
    filesToSend: [],
    name: '',
    uploadingFiles: false,
    sendingFiles: false
  };

  componentDidMount() {
    this.setState({
      componentHasMounted: true
    });
  }

  toggleDropZoneModal = () => {
    this.setState((prevState) => ({
      dropZoneModalIsOpen: !prevState.dropZoneModalIsOpen
    }));
  };

  handleDragEvent = () => {
    this.setState((prevState) => ({
      changeDropZoneBackgroundColor: !prevState.changeDropZoneBackgroundColor
    }));
  };

  handleDrop = (droppedFiles) => {
    this.setState((prevState) => ({
      filesToSend: [...prevState.filesToSend, ...droppedFiles],
      changeDropZoneBackgroundColor: false
    }));
  };

  removeFile = (fileToBeRemoved) => {
    this.setState((prevState) => ({
      filesToSend: prevState.filesToSend.filter((file) => file !== fileToBeRemoved)
    }));
  };

  encodeData = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key === 'files') {
        const formattedFileName = `${this.props.user.name
          .toLowerCase()
          .replace("'", '')
          .split(' ')
          .join('-')}-level-${this.props.selectedLevel}${
          data[key].name ? data[key].name.slice(data[key].name.indexOf('.')) : '.zip'
        }`;
        return formData.append(key, data[key], formattedFileName);
      }
      return formData.append(key, data[key]);
    });

    return formData;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    this.setState({
      uploadingFiles: true
    });

    setTimeout(() => {
      this.setState({
        uploadingFiles: false,
        sendingFiles: true
      });
    }, 5000);

    const { user } = this.props;
    const { filesToSend } = this.state;

    if (filesToSend.length > 1) {
      zipFilesWorker
        .zipFiles(filesToSend, isEdge, browserVersion)
        .then((zippedFiles) => {
          this.sendData(form, user, zippedFiles);
        })
        .catch((error) => console.log(error));
    } else {
      const oneFile = filesToSend[0];
      this.sendData(form, user, oneFile);
    }
  };

  sendData = (form, user, data) => {
    const { game, selectedLevel, toggleLevelCompleteModal } = this.props;
    const { name, email } = user;
    axios({
      url: '/',
      method: 'POST',
      data: this.encodeData({
        'form-name': form.getAttribute('name'),
        game: game.title,
        name,
        email,
        files: data
      })
    })
      .then(() => {
        this.setState({
          sendingFiles: false,
          filesToSend: []
        });
        this.toggleDropZoneModal();
        toggleLevelCompleteModal(selectedLevel);
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { data, viewportWidth, user, game, selectedLevel } = this.props;
    const { levelData, featheredQuillAndParchment } = data;
    const {
      benefits,
      challenge,
      duration,
      levelPageSummary,
      levelVideoCode,
      objective,
      scoring,
      title
    } = levelData.childMarkdownRemark.frontmatter;
    let { tools } = levelData.childMarkdownRemark.frontmatter;
    const {
      changeDropZoneBackgroundColor,
      componentHasMounted,
      dropZoneModalIsOpen,
      email,
      filesToSend,
      name,
      uploadingFiles,
      sendingFiles
    } = this.state;
    if (game && game.uniqueDocs) {
      game.uniqueDocs.forEach((uniqueDocs) => {
        if(uniqueDocs.level === selectedLevel) {
          tools = {
            list: tools.list,
            link: uniqueDocs.link
          }
        }
      })
    }
    return user ? (
      <Wrapper>
        <SEO pageSpecificTitle="Level 2" pageSpecificDescription={levelPageSummary} />
        <LevelHero
          level={2}
          title={title}
          levelPageSummary={levelPageSummary}
          levelIcon={level2Icon}
          levelVideoCode={levelVideoCode}
        />
        <LevelObjective objective={objective} />
        <LevelChallenge challenge={challenge} duration={duration} tools={tools} />
        <Rodal
          customStyles={{
            borderRadius: '2px 0 2px 2px',
            overflow: 'hidden',
            maxWidth: '498px',
            width: '100%',
            height: 'auto',
            bottom: 'auto',
            top: '101px',
            padding: 0
          }}
          showCloseButton={false}
          visible={dropZoneModalIsOpen}
          onClose={() => this.toggleDropZoneModal()}>
          <DropZoneWrapper changeDropZoneBackgroundColor={changeDropZoneBackgroundColor}>
            <CloseModal onClick={() => this.toggleDropZoneModal()}>
              <img src={crossmark} alt="crossmark" />
            </CloseModal>
            <h3>Upload</h3>
            <p>Level {selectedLevel} Documents</p>
            <Form
              name="Level 2 Submission"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}>
              <Input type="hidden" name="form-name" value="Level 2 Submission" />
              <div hidden>
                <Label>
                  Don’t fill this out:
                  <Input name="bot-field" onChange={this.handleInputChange} />
                </Label>
              </div>
              <div style={{ position: 'absolute', opacity: 0, zIndex: -1 }}>
                <Label>
                  Game
                  <Input type="hidden" name="game" aria-label="game" />
                </Label>
              </div>
              <div style={{ position: 'absolute', opacity: 0, zIndex: -1 }}>
                <Label>
                  Name
                  <Input type="hidden" name="name" value={name} aria-label="name" />
                </Label>
              </div>
              <div style={{ position: 'absolute', opacity: 0, zIndex: -1 }}>
                <Label>
                  Email
                  <Input type="hidden" name="email" value={email} aria-label="email" />
                </Label>
              </div>
              <Dropzone
                onDrop={this.handleDrop}
                onDragEnter={this.handleDragEvent}
                onDragLeave={this.handleDragEvent}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="dropzone-area">
                      <input {...getInputProps()} name="files" />
                      <p style={{ pointerEvents: 'none' }}>
                        <span style={{ fontWeight: 600 }}>Drag & drop</span> or{' '}
                        <span style={{ fontWeight: 600, textDecoration: 'underline' }}>click</span>{' '}
                        to browse files
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <FileNames>
                {filesToSend.map((file, i) => (
                  <FileName key={i} onClick={() => this.removeFile(file)}>
                    {file.name}
                    <img src={checkmark} alt="checkmark" />
                    <img src={crossmarkBlack} alt="crossmark" />
                  </FileName>
                ))}
              </FileNames>
              <SubmitButton
                type="submit"
                uploadingFiles={uploadingFiles}
                sendingFiles={sendingFiles}
                disabled={filesToSend.length < 1}>
                {uploadingFiles
                  ? 'Uploading'
                  : sendingFiles
                  ? 'Sending'
                  : `Send File${filesToSend.length > 1 ? 's' : ''}`}
              </SubmitButton>
            </Form>
          </DropZoneWrapper>
        </Rodal>
        <BraddOverview>
          <div>
            <BraddLetter>B</BraddLetter>
            <p>
              <span>Brief</span> - Write an outline of the brief and be sure to include{' '}
              <ul style={{ listStyleType: 'none' }}>
                <li>
                  <span>&#183;</span>
                  <p>The overarching objective</p>
                </li>
                <li>
                  <span>&#183;</span>
                  <p>What does success look like?</p>
                </li>
                <li>
                  <span>&#183;</span>
                  <p>Specifically, what must be included that the final campaign must address?</p>
                </li>
                <li>
                  <span>&#183;</span>
                  <p>
                    Measurements - KPIS - give your best idea of how you will measure its success.
                  </p>
                </li>
                <li>
                  <span>&#183;</span>
                  <p>
                    Specifically, what must be avoided? Things that should be avoided (perhaps
                    because your competitors are doing that, or you know from previous experience
                    that it doesn&apos;t work)
                  </p>
                </li>
              </ul>
            </p>
          </div>
          <div>
            <BraddLetter>R</BraddLetter>
            <p>
              <span>References</span> - Ideas that you like from other brands/campaigns that you may
              have seen.
            </p>
          </div>
          <div>
            <BraddLetter>A</BraddLetter>
            <p>
              <span>Assets</span> - List the campaign assets that are currently available that should be
              included in the final campaign - e.g. brand guidelines/logos/websites/supporting
              research/data, etc.
            </p>
          </div>
          <div>
            <BraddLetter>D</BraddLetter>
            <p>
              <span>Deliverables</span> - What specifically do you want the campaign to produce?
              e.g. new brand ID/a HCP website/multimedia campaign/digital campaign/educational
              brochures/virtual events, etc.
            </p>
          </div>
          <div>
            <BraddLetter>D</BraddLetter>
            <p>
              <span>Deadline</span> - When do you require the campaign components to go live?
            </p>
          </div>
          <UploadDocs>
            You can upload doc, ppt or pdf file
            <button type="button" onClick={this.toggleDropZoneModal}>
              Click To Upload BRADD
            </button>
          </UploadDocs>
        </BraddOverview>
        <InnerWrapper>
          <LevelBenefits
            benefits={benefits}
            level={selectedLevel}
            objective={objective}
            viewportWidth={viewportWidth}
            scoring={scoring}
          />
        </InnerWrapper>
        <Inspiration
          img={featheredQuillAndParchment}
          author="Blaise Pascal, 1657<span>Blaise knew the challenges of brief writing!</span />"
          quote="Excuse the long letter, I didn’t have time to write a short one!"
          quoteLargeFontSize="4rem"
          maxWidth="36.563em"
          textWrapperTop="6em"
          viewportWidth={viewportWidth}
        />
      </Wrapper>
    ) : (
      componentHasMounted && <Redirect to="/" noThrow />
    );
  }
}

const FileNames = styled.div`
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-top: 1.125em;
`;

const FileName = styled.span`
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 2em;
  position: relative;
  text-align: left;
  transition: color 100ms ease-in-out;

  img {
    position: absolute;
    transition: opacity 100ms ease-in-out;
  }

  img[alt='checkmark'] {
    top: 5px;
    right: -20px;
  }

  img[alt='crossmark'] {
    top: 6px;
    right: -18px;
  }

  &:hover {
    color: #000;

    img[alt='checkmark'] {
      opacity: 0;
    }

    img[alt='crossmark'] {
      opacity: 1;
    }
  }

  &:not(:hover) {
    color: var(--cx-dark-green);

    img[alt='checkmark'] {
      opacity: 1;
    }

    img[alt='crossmark'] {
      opacity: 0;
    }
  }
`;

const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;

  > h3,
  > p,
  > form {
    text-align: center;
  }

  > h3 {
    font-size: 2rem;
    margin-top: 1em;
  }

  > p {
    margin-top: 0.5em;
    margin-bottom: 1.55em;
  }

  .dropzone-area {
    align-items: center;
    border-radius: 0.125em;
    cursor: pointer;
    display: flex;
    height: 6rem;
    justify-content: center;
    outline: none;
    transition: background-color 150ms ease-in-out, border 150ms ease-in-out;

    ${({ changeDropZoneBackgroundColor }) =>
      changeDropZoneBackgroundColor
        ? css`
            background-color: rgba(0, 146, 69, 0.3);
            border: 0.063rem solid var(--cx-dark-green);
          `
        : css`
            background-color: rgba(239, 237, 228, 0.3);
            border: 0.063rem solid #c1beac;
          `};
  }
`;

const CloseModal = styled.button`
  background-color: var(--cx-dark-green);
  width: 2.875rem;
  height: 2.875rem;
  cursor: pointer;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
`;

const UploadDocs = styled.div`
  align-items: center;
  align-self: flex-end;
  color: #c1beac;
  display: flex;
  font-weight: 600;

  button {
    background-color: #000;
    color: #fff;
    cursor: pointer;
    display: block;
    margin-left: 1.875em;
    font-size: 1rem;
    font-weight: 700;
    height: 2.875em;
    outline-color: var(--cx-dark-green);
    text-transform: uppercase;
    width: 15.5em;
  }
`;

const Wrapper = styled.section``;

const Label = styled.label``;

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 9.1%;
`;

const BraddOverview = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  padding: 0 9.027777777777777% 2.625rem;
  display: flex;
  flex-direction: column;

  > div:not(:last-of-type) {
    align-items: flex-start;
    display: inline-flex;
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 2.75em;

    > p {
      margin-left: 0.833em;
      position: relative;
      top: 0.325em;
      font-weight: 400;
      line-height: 1.333em;
      max-width: 874px;

      > span {
        font-weight: 700;
      }
    }

    ul {
      margin-top: 0.75em;
      line-height: 1.333em;

      li {
        display: flex;
        margin-bottom: 0.75em;

        span {
          margin-right: 1.5em;
        }
      }
    }
  }
`;

const BraddLetter = styled.span`
  background-color: var(--cx-dark-green);
  color: #fff;
  font-size: 1.5rem;
  height: 1.917em;
  width: 1.917em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.125em;
  position: relative;
  bottom: 0.02em;
  flex-shrink: 0;
  font-weight: 600;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  background-color: transparent;
  font-size: 1.25rem;
  padding: 0.5em;
  width: 100%;
  height: 40px;
  outline-color: var(--cx-dark-green);

  &::placeholder {
    font-size: 1.25rem;
    font-style: italic;
  }
`;

const SubmitButton = styled.button`
  align-self: flex-end;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: 2.875em;
  margin-top: 1.2em;
  outline-color: var(--cx-dark-green);
  text-transform: uppercase;
  transition: background-color 150ms ease-in-out;
  width: 9.25em;

  ${({ uploadingFiles, sendingFiles }) =>
    (uploadingFiles || sendingFiles) &&
    css`
      &:after {
        content: ' .';
        margin-left: -3px;
        animation: dots 1s steps(5, end) infinite;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
        }
      }
    `}

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: #c1beac;
          pointer-events: none;
        `
      : css`
          background-color: var(--cx-dark-green);
          pointer-events: auto;
        `};
`;

export const Level2Query = graphql`
  {
    levelData: file(sourceInstanceName: { eq: "levelData" }, relativePath: { eq: "level_02.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          levelPageSummary
          levelVideoCode
          challenge {
            heading
            detail
          }
          objective
          benefits {
            forPlayers
            forTheCampaign
          }
          duration {
            time
            detail
          }
          scoring {
            challenge {
              points
              detail
            }
            teamGame {
              points
              detail
            }
          }
          tools {
            list
            link
          }
        }
      }
    }
    featheredQuillAndParchment: file(
      relativePath: { eq: "Shared/Inspiration/feathered_quill_and_parchment.png" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 585, maxHeight: 620) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Level2;
